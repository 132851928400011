header.side {

	background-color: transparent;
	width: 100%;
	top: 0;
	position: relative;
	height: auto;

	nav.main-menu {
		width: 100%;
		border: none;
		min-height: inherit;
		background-color: white;
		position: relative;
		z-index: 102;
		left: 0;
		padding:10px;
		-webkit-overflow-scrolling: touch;

		a.backhome {
			position: relative;
			display: inline-block;
			float: left;
			width:33%;
			height:66px;
			text-indent: -999px;
			background: transparent url('/themes/cigatec-bootstrap/img/cigatec-mobile.png') no-repeat center center;
			margin-top:0;
		}

		ul.navigation {
			overflow: hidden;
			height: 0;
			display:none;
		}

		nav.mobile-section {
			position: relative;
			width: 47%;
			float: left;
		}
	}

	nav.main-menu,
	nav.main-menu nav.mobile-section ul.menu {
		border-left: 1px solid #d9d9d9;
		border-right: 1px solid #d9d9d9;
		width: 100%;
		padding-left:0;
	}

}


nav.sub-menu {
	display:none;
}

header.side nav.main-menu nav.mobile-section ul.menu  li {
	position: relative;
	display: inline-block;
	float: left;
	text-align: center;
	transition: all .25s;
	width: 50%;
}

header.side nav.main-menu nav.mobile-section ul.menu  li a {
	font-weight: 100;
	height: 66px;
	display: block;
	font-size: 12px;
	color: black;
	line-height: 72px;
	outline: 0;
	text-decoration: none;
}

header.side nav.main-menu nav.mobile-section ul.menu  li:first-child {
	border-right: 1px solid #d9d9d9;
}


header.side nav.main-menu nav.mobile-section ul.menu ul.sub-menu {
	display:none;
}

header nav.main-menu div.menu-trigger {
	width: 18%;
	float: left;
	transition: all .25s;
}

header#sidemenu div.first-level div.top-nav div.menu-trigger.closed {
	transition: all .25s;
	background: #d9d9d9;
}

header.side nav.main-menu div.menu-trigger button.burger {
	width: 75%;
	height: 66px;
	display: inline-block;
	float: right;
	margin-top: 0;
	border: none;
	outline: none;
	background: none
}

button.burger div.lines {
	width: 30px;
	margin: 0 auto;
	height: 1px;
	background: black;
	transition: 0.3s;
	position: relative;
}

.lines-button {
	transition: .3s;
	cursor: pointer;
	user-select: none;
}

.lines-button:hover {
	opacity: 1;
}

.lines-button:active {
	transition: 0;
}

.lines {
	display: inline-block;
	width: 30px;
	margin: 0 auto;
	height: 1px;
	background: black;
	transition: 0.3s;
	position: relative;
}

.lines:before, .lines:after {
	display: inline-block;
	width: 30px;
	margin: 0 auto;
	height: 1px;
	background: black;
	transition: 0.3s;
	position: absolute;
	left: 0;
	content: '';
	-webkit-transform-origin: 2.85714px center;
	transform-origin: 2.85714px center;
}

.lines:before {
	top: -7px;
}

.lines:after {
	top: 7px;
}

.lines-button.closed {
	-webkit-transform: scale3d(0.8, 0.8, 0.8);
	transform: scale3d(0.8, 0.8, 0.8);
}

.lines-button.x.closed .lines {
	background: transparent;
}

.lines-button.x.closed .lines:before, .lines-button.x.closed .lines:after {
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	top: 0;
	width: 100%;
}

.lines-button.x.closed .lines:before {
	-webkit-transform: rotate3d(0, 0, 1, 45deg);
	transform: rotate3d(0, 0, 1, 45deg);
}

.lines-button.x.closed .lines:after {
	-webkit-transform: rotate3d(0, 0, 1, -45deg);
	transform: rotate3d(0, 0, 1, -45deg);
}

.mobile-menu {
	overflow-y: scroll;
	border-top: 1px solid #d9d9d9;
	margin-bottom: -1px;
	background-color: #000;
	height: 0;
	width: 100%;
	text-transform:uppercase;
}

.mobile-menu.closed {
	/*
	height:auto;
	*/
}


.mobile-menu .navigation {
	margin-top:42px;
	padding-left:0;
	background-color: #000;
	color:#fff;
	width:95%;
	margin:0 auto;
}

.mobile-menu .nav > li > a {
	text-decoration: none;
	background-color: transparent;
	color:#fff;
	font-size: 4vh;
	font-weight:bold;
	
}

.mobile-menu .nav > li > a:hover, .mobile-menu .nav > li > a:focus {
	text-decoration: none;
	background-color: #222;
}

.mobile-menu [class^="icon-"], .mobile-menu [class*=" icon-"] {
	margin-right:2vw;
}

.mobile-menu .mobile-submenu {
	padding-left:10vw;
	margin-bottom:1vh;
}

.mobile-menu .mobile-submenu  > li > a {
	text-decoration: none;
	font-size:2vh;
	color:#fff;
	font-weight:bold;
}


div.main {
	margin-left: 0;
}


div.main .breadcrumb-container {
	position: relative;
}

.top-wrapper {
	padding: 5vh 0 5vh 5vh;
}
