header.side {
	position: fixed;
	top: 0;
	width: 300px;
	height: 100%;
	z-index: 9999;
	background-size: cover;

	nav.main-menu {
		overflow: hidden;
		border-right: 1px solid #d9d9d9;
		width: 300px;
		height: 100%;
		background-color: #ffffff;
		position: fixed;
		z-index: 102;
		left: 0;
		-webkit-overflow-scrolling: touch;
		text-align: center;

		a.backhome {
			display:block;
			margin-left:95px;
			width:111px;
			height:206px;
			text-indent: -999px;
			background: url('/themes/cigatec-bootstrap/img/cigatec-logo.png') no-repeat;
		}

		ul.navigation {
			list-style-type: none;
			padding-left:0;
			height: 80vh;
			overflow-y: auto;
			margin-top:2vh;
			border-top: 1px solid #d9d9d9;
			width:100%;
		
			li {
				border-bottom: 1px solid #d9d9d9;
				transition: all .25s;

				a {
					display:block;
					padding:1vh 0;
					height:15vh;
					position:relative;
					font-size: 12px;
					letter-spacing: 1px;
					transition: all .25s;
					color: #000;
					text-transform: lowercase;
					
					span.label-nav {
						top:50%;
						display:table;
						margin:0 auto;
					}
				
					[class^="icon-"], [class*=" icon-"] {
						line-height:1.5;
						font-size:6vh;
					}
				
					&:hover,&.active {
						text-decoration: none;
						transition: all .25s;
						background-color: #f3f3f3;
					}
					&.active {
						background-color: #f3f3f3;
					}
				}
			}
		}
	}

	nav.sub-menu {
		text-transform: lowercase;
		left: -100px;
		width: 350px;
		min-height: 100%;
		position: fixed;
		background-color: #f3f3f3;
		color: #000000;
		z-index: 101;
		transform: translateX(0);
		transition: transform 0.4s 0s, box-shadow 0s 0.4s;
		text-align: center;
		box-shadow: 0 0 16px -6px #000000;
		-webkit-box-shadow: 0 0 16px -6px #000000;
		-moz-box-shadow: 0 0 16px -6px #000000;

		ul.navigation {
			list-style-type: none;
			margin-left: 50px;
			width: 300px;
			margin-top: -1px;
			border-top: 1px solid #d9d9d9;
			height: 95vh;
			overflow-y: auto;
		}
	}
}


header nav.main-menu nav.mobile-section, .menu-trigger {
	display:none;
}

div.main, .footer-wrapper {
	margin-left: 300px;
	z-index: 1;
}
