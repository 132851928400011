@import "compass";
@import "_theme_variables";


/* 
	==================
	[GLOBAL]
	==================
*/

html {
	height: 100%;
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	position: relative;
	margin: 0;
	padding-bottom: 360px;
	min-height: 100%;
	background-color:white;
}

@media (min-width: 768px) {
  .container-fluid {
    max-width: 750px;
  }
}
@media (min-width: 992px) {
  .container-fluid {
    max-width: 970px;
  }
}
@media (min-width: 1200px) {
  .container-fluid {
    max-width: 1170px;
  }
}
/*
@alternateColor:#E4DCD5;
@alternateColorHover:#CEC6C1;
*/
$alternateColor:#f3f3f3;
$alternateColorHover:#dedede;
$footerColor:#f2f2f2;

/* 
	==================
	[HEADER]
	==================
*/

header.side {

	position: fixed;
	bottom: 0;
	width: 225px;
	height: 100vh;
	z-index: 9999;
	box-shadow: 0 0 16px -6px #000000;
	-webkit-box-shadow: 0 0 16px -6px #000000;
	-moz-box-shadow: 0 0 16px -6px #000000;
	background-size: cover;
/*
	@include clearfix;
*/
	div.nav {
		position: relative;
		z-index:102;

		.cartBox, .header_user_info {
			background-color:#fff;
			padding-bottom:0;
			
		
		}

	}

	nav.main-menu {

		overflow: hidden;
		border-right: 1px solid #d9d9d9;
		width: 225px;
		min-height: 100vh;
		background-color: $white;
		position: fixed;
		z-index: 102;
		left: 0;
		-webkit-overflow-scrolling: touch;
		text-align:center;

		a.backhome {
			display:block;
			margin-top:1vh;
		}

		ul.navigation {
			list-style-type: none;
			padding-left:0;
			height: 80vh;
			overflow-y: auto;
			margin-top:10vh;
			border-top: 1px solid #d9d9d9;
			width:100%;
		
			li {
				border-bottom: 1px solid #d9d9d9;
				transition: all .25s;

				a {
					display:block;
					padding:1vh 0;
					height:15vh;
					position:relative;
					font-size: 12px;
					letter-spacing: 1px;
					transition: all .25s;
					color: $black;
					text-transform: lowercase;
					
					span.label-nav {
						top:50%;
						display:table;
						margin:0 auto;
					}
				
					[class^="icon-"], [class*=" icon-"] {
						line-height:1.5;
						font-size:6vh;
					}
				
					&:hover,&.active {
						text-decoration: none;
						transition: all .25s;
						background-color: $alternateColor;
					}
					&.active {
						background-color: $alternateColor;
					}
				}
			}
		}
	}
	nav.sub-menu {

		text-transform: lowercase;
		left: -100px;
		top:0;
		width: 350px;
		min-height: 100%;
		position: fixed;
		background-color: $alternateColor;
		color:$black;
		z-index: 101;
		transform: translateX(0);
		transition: transform 0.4s 0s, box-shadow 0s 0.4s;
		text-align:center;

		&.visible {
			transform: translateX(100%);
			transition: transform 0.4s 0s, box-shadow 0s 0s;
		}

		ul.navigation {
			list-style-type: none;
			margin-left: 50px;
			width: 300px;
			margin-top: -1px;
			border-top: 1px solid #d9d9d9;
			height: 95vh;
			overflow-y: auto;

			li {

				border-bottom: 1px solid $gray;
				transition: all .25s;

				&.active, &:hover {
					transition: all .25s;
					background-color: #f4f4f4;
				}

				a {
					display:block;
					padding:1vh 0;
					height:10vh;
					position:relative;
					font-size: 12px;
					letter-spacing: 1px;
					transition: all .25s;
					color: $black;
					text-transform: lowercase;

					span.label-nav {
						top:50%;
						display:table;
						margin:0 auto;
					}
				
					[class^="icon-"], [class*=" icon-"] {
						line-height:1.5;
						font-size:4vh;
					}
				
					&:hover {
						text-decoration: none;
						transition: all .25s;
						background-color: $alternateColorHover;
					}
				}

				&.allfunctions {
					a {
						color:$red;
					}
				}
				&:first-child {
					border-top: 1px solid $gray;
				}

			}
		}

		&.e-cigarettes {
			ul.navigation {
				li {
					a {
						&:hover {
							background-color:$orange;
						}
					}
				}
			}
		}

		&.coin-des-experts {
			ul.navigation {
				li {
					a {
						&:hover {
							background-color:$yellow;
						}
					}
				}
			}
		}

		&.e-liquides {

			background-color: #fff;

			ul.navigation {
				li {
					display:block;
					height:101px;
					&:first-child {
						border-top: none;
					}
					&.active, &:hover {
						transition: none;
						background-color: #fff;
					}
					a {
						height:100px;
						padding:10px 0;
						padding: 0;

						span.label-nav {
							display: block;
							width:100%;
							height: 32px;
							padding: 5px 0;
							line-height:22px;
							background-color:#fff;
						}
						
						span.ejuices {
							display:block;
							height:65px;
							margin: 3px auto;
							text-align: center;
							width: 125px;
							background-repeat:no-repeat;
							background-color:transparent;
							background-image:url('/themes/cigatec-bootstrap/img/eliquid-brand.jpg');
							background-repeat:no-repeat;
							background-position:0px 0px;
							&.good-life-vapor {
								background-position:0px -685px;
							}
							&.the-sweet-shoppe {
								background-position:0px -250px;
							}
							&.five-pawns {
								background-position:0 -748px;
							}
							&.rope-cut {
								background-position:0 -375px;
							}
							&.beard-vape {
								background-position:0 -938px;
							}
							&.mylk {
								background-position:0 -498px;
							}
							&.t-juice {
								background-position:0 -313px;
							}
							&.vampire-vape {
								background-position:0 -63px;
							}
							&.halo {
								background-position:0 -623px;
							}
							&.manabush {
								background-position:0 -560px;
							}
							&.addiction {
								background-position:0 -2500px;
							}
							&.pulp {
								background-position:0 -437px;
							}
							&.alfaliquid {
								background-position:0 -1000px;
							}
							&.exaliquid {
								background-position:0 -813px;
							}
							&.pemp-5-par-exaliquid {
								background-position:0 -813px;
							}
							&.bordo2 {
								background-position:0 -876px;
							}
							&.magic-elixirs-me {
								background-position:0 -2500px;
							}
							&.totally-wicked {
								background-position:0 -188px;
							}
							&.twelve-monkeys {
								background-position:0 -125px;
							}
							&.eliquides-814 {
								background-position:0 -1063px;
							}
							&.addiction {
								background-position:0 -1126px;
							}
							&.le-french-liquide {
								background-position:0 -1181px;
							}
							&.just-jam {
								background-position:0 -1244px;
							}
						}
						&:hover {
							text-decoration: none;
							transition: none;
							background-color: #fff;
							span.label-nav {
								transition: all .25s;
								background-color:#FA2778;
								color:#fff;
								font-weight:bold;
							}
						}
					}
				
				}
			}
		}

	}
/*

*/
}
/* 
	==================
	[BANNER]
	==================
*/
.banner-container {
	background-color:#f2f2f2;
	border-bottom:1px solid $darkPrimaryColor;
	.banner {
	}
}

ul.bannerlinks {
	margin-bottom:0;
	li {
		display:inline-block;
		margin-right:20px;
		a {
			text-transform: uppercase;
			display:block;
			padding: 9px 20px;
		}
	}
}

/* 
	==================
	[FOOTER]
	==================
*/

.footer-container {
	background-color: #222222;
	
	.container {
		padding-bottom: 1.5em;
		padding-top: 3em
	}

	
		border-top: 2px solid #333;
		margin-top: 35px;
/*
		padding-top: 35px;
*/

}

/* 
	==================
	[ICONS]
	==================
*/
@font-face {
    font-family: 'cigatec';
    src:    url('/themes/cigatec-bootstrap/fonts/cigatec/cigatec.eot?pm4rce');
    src:    url('/themes/cigatec-bootstrap/fonts/cigatec/cigatec.eot?pm4rce#iefix') format('embedded-opentype'),
        url('/themes/cigatec-bootstrap/fonts/cigatec/cigatec.ttf?pm4rce') format('truetype'),
        url('/themes/cigatec-bootstrap/fonts/cigatec/cigatec.woff?pm4rce') format('woff'),
        url('/themes/cigatec-bootstrap/fonts/cigatec/cigatec.svg?pm4rce#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'cigatec' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*

.icon-atomiseurs:before {
    content: "\e900";
}
.icon-e-cigarettes:before {
    content: "\e901";
}
.icon-e-liquides:before {
    content: "\e902";
}
.icon-coin-des-experts:before {
    content: "\e903";
}
*/
.icon-shop:before {
  content: "\e908";
}

.icon-mods-et-boxes:before {
  content: "\e907";
}
.icon-kits:before {
  content: "\e906";
}
.icon-acesssoires:before {
  content: "\e918";
}
.icon-cotons:before {
  content: "\e916";
}
.icon-fils-resistifs:before {
  content: "\e914";
}
.icon-drippers:before {
  content: "\e915";
}
.icon-accus:before {
  content: "\e904";
}
.icon-clearomiseurs:before {
  content: "\e905";
}
.icon-chargeurs:before {
  content: "\e90f";
}
.icon-batteries:before {
  content: "\e910";
}
.icon-e-cigarettes:before {
  content: "\e901";
}
/*
.icon-pices-dtaches:before {
  content: "\e911";
}
*/
.icon-pices-dtaches:before {
  content: "\e917";
}
.icon-accessoires:before {
  content: "\e912";
}
.icon-outillage:before {
  content: "\e913";
}
.icon-rsistances:before {
  content: "\e922";
}
.icon-atomiseurs:before {
  content: "\e900";
}
.icon-e-liquides:before {
  content: "\e902";
}
.icon-coin-des-experts:before {
  content: "\e903";
}



