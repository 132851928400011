// Make sure the charset is set appropriately
@charset "UTF-8";

@import "compass/reset";
@import "_theme_variables";
@import "bootstrap";
//@import "material-design/bootstrap-material-design";
@import "cigatec";
@import "font-awesome";
@import "material-design";

@media(max-width: $screen-xs-max){
	@import "responsive/screen-xs";

}
@media(min-width: $screen-tablet){
	@import "responsive/screen-sm";

}     /* or @screen-sm */
@media(min-width: $screen-desktop){
	@import "responsive/screen-md";

}    /* or @screen-md */
@media(min-width: $screen-lg-desktop){
	@import "responsive/screen-lg";

} /* or @screen-lg */


// material design


a:hover {
	text-decoration: none;
}

@media only screen and (min-width: 1200px) {
	.container {
		padding-left: 0;
		padding-right: 0;
	}
}

body {
	min-width: 320px;
	height: 100%;
	font:$font;
	color: $base-body-color;
	position:relative;
	&.content_only {
		background: none;
	}
}
a {
	&:hover, &:focus, &:active {
		outline:none;
		text-decoration:none;	
	}
}
.btn:active, .btn:focus, .btn.active {
	outline:none;	
}
textarea {
	resize: none;
}
sup, .required {
	color:red;	
}

.radio label, .checkbox label {
	padding-left:0;	
}

/* -------------------------------------------------------------------------------
					 			BackToTop
------------------------------------------------------------------------------- */

#toTop:before {
    content: "";
    display: block;
    font-family: $font-icon;
    font-size: 38px;
	line-height:48px;
    vertical-align: 0;
	opacity:0.5;
	color:#333;
	-webkit-transition: all 0.3s linear;
	-moz-transition: all 0.3s linear;
	-ms-transition: all 0.3s linear;
	-o-transition: all 0.3s linear;
	transition: all 0.3s linear;
	height:50px;
	width:50px;
	text-align:center;
	background:#fff;
	-webkit-box-shadow: 0 1px 5px rgba(0,0,0,0.75);
-moz-box-shadow: 0 1px 5px rgba(0,0,0,0.75);
box-shadow: 0 1px 5px rgba(0,0,0,0.75);

}
#toTop {
	border: medium none;
	height:100px;
	width:100px;
	padding:25px;
    bottom: 100px;
    cursor: pointer;
    display: none;
    right: 0;
    overflow: hidden;
    position: fixed;
    text-align: center;
    text-decoration: none;
    z-index: 999;
	-webkit-transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-ms-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
	@media (max-width: $screen-xs-max) { // max 767px
		display: none!important;
	}
}
#toTop:hover{
	&:before{
		opacity:0.8;
	}
}

/* -------------------------------------------------------------------------------
					 			STRUCTURE
------------------------------------------------------------------------------- */

#page {
	overflow:hidden;
	/*
	padding-left:300px;
	@media (min-width: $screen-lg) { // min 1200px
		&:before {
			content:"";
			display:block;
			position:absolute;
			left:300px;
			top:0;
			width:2px;
			height:100%;
			background:#d9d9d9;	
		}
	}
	*/
/*
	.scroll-wrapper, > .scrollbar-inner {
		width:300px;
		position:fixed !important; 
		left:0; 
		top:0;
		z-index:105;
		overflow-y:auto;
		height:100%;
	}
*/
	.menu_overlay { display:none;}
	@media (max-width: $screen-md-max) { // max 1199px
	//padding-left:0;
		.scrollbar-inner {
			/*
			position:fixed !important;
			width:280px !important;
			left:-280px;
			top:0;
			overflow-y:auto;
			height:100%;
			background:#fff;
			z-index:999;
			opacity:0;
			@include transition(all 0.5s ease);
			*/
		}
		span.open, span.close {
			line-height:40px;
			font-size:40px;
			text-align:center;
			color:#fff;
			height:40px;
			width:40px;
			display:block;
			background:#333;
			position:fixed;
			left:0;
			top:41px;
			z-index:2;
			z-index:3;
			@include transition(all 0.5s ease);
			opacity:1;
			&:before {
				content:"\e0de";
				font-family:$flat-icon;	
			}
		}
		span.close {
			z-index:2;
			opacity:0;
			&:before {
				content:"\e054";
			}
			&:after {
				content:"";
				display:block; 
				z-index:-3; 
				position:fixed; 
				left:-10px; 
				top:0; 
				width:0; 
				height:100%; 
				background:#000; 
				@include transition(all 0.5s ease); 
				opacity:0;
				&:hover {
					width:0;
					left:-10px;
					opacity:0;	
				}	
			}
			&:hover {
				&:after {
					width:0;
					left:-10px;
					opacity:0;
				}	
			}
		}
		span.open:hover {
			z-index:1;
			opacity:0;
			+ .scrollbar-inner { 
				left:0; 
				opacity:1;
				+ span.close {
					left:280px;
					opacity:1;
					&:after {
						width:100%;
						left:0;
						opacity:0.7;
					}
				}
			}
		}
		.scrollbar-inner:hover { 
			left:0; 
			opacity:1;
			+ span.close {
				left:280px;
				opacity:1;
				&:after {
					width:100%;
					left:0;
					opacity:0.7;
				}
			}
		}
	}
}

.columns-container {
	position:relative;
	z-index:0;
	@media (min-width: $screen-lg) { // min 1200px
		padding-bottom:150px;
	}
	@media (max-width: $screen-md-max) { // max 1199px
		overflow:hidden;
	}
}
#category .columns-container {
	@media (min-width: $screen-lg) { // min 1200px
		padding-bottom:150px;
	}
}

#columns {
	position: relative;
	@media (min-width: $screen-lg) and (max-width: $screen-lg + 380) { // min 1200px max 1580
		&.container {
			width: 840px;
			.large-left.col-sm-9 {
				width:69%;	
			}
			#right_column {
				width:31%;	
			}
		}
	}
}

#index {
	.tab-content {
		margin-top: 35px;
	}
	.columns-container {
		overflow:hidden;
		padding-bottom:0;
	}
}

header {
	background: $light-background;
	/*
	padding-bottom: 15px;
	*/
	.banner {
		background: black;
		max-height: 100%;
		.row {
			margin: 0;
		}
	}
	.nav {
		.row {
			margin: 0;
		}
		nav {
			width: 100%;
		}
	}
	#header_logo {
		padding: 15px;
		img {
			margin: 0 auto;
			width:auto;
		}
	}
	@media (min-width: $screen-xs-max){
		.col-sm-4 + .col-sm-4 + .col-sm-4 {
			float: right;
		}
	}
}

.ie8 #header #header_logo {
	width: 350px;
}

/* ----------------------------------------------------------------------------
							 		TITLE
------------------------------------------------------------------------------ */

#center_column {
	.page_product_box {
		h3 {
			color: $base-heading-color;
			text-transform: uppercase;
			font-family: $font-custom;
			font-weight: 600;
			font-size: 18px;
			line-height: 20px;
			padding: 14px 0px 0px;
			margin: 0 0 20px;
			position: relative;
		}
	}
}

#center_column {
	@media only screen and (min-width:$screen-xs-max){
		float:right;	
	}
}

/* ---------------------------------------------------------------------------
								 	TEXT 
---------------------------------------------------------------------------- */

p.info-title {
	font-weight: bold;
	color: $base-text-color;
	margin-bottom: 25px;
}

p.info-account {
	margin: -4px 0 24px 0;
}

.dark {
	color: $base-text-color;
}

.main-page-indent {
	margin-bottom: $base-page-indent;
}

.alert {
	font-weight: bold;
	ul,
	ol {
		padding-left: 15px;
		margin-left: 27px;
		li {
			list-style-type: decimal;
			font-weight: normal;
		}
	}
	&.alert-success {
		text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
		&:before {
			font-family: $font-icon;
			content: "\f058";
			font-size: 20px;
			vertical-align: -2px;
			padding-right: 7px;
		}
	}
	&.alert-danger {
		text-shadow: 1px 1px rgba(0, 0, 0, 0.1);
		&:before {
			font-family: $font-icon;
			content: "\f057";
			font-size: 20px;
			vertical-align: -2px;
			padding-right: 7px;
			float: left;
		}
	}
}

.label {
	white-space: normal;
	display: inline-block;
	padding: 6px 10px;
}

label {
	color: $base-title-color;
	font-weight:300 !important;
	text-transform:uppercase;
	&.required {
		&:before {
			content: "*";
			color: red;
			font-size: 14px;
			position: relative;
			line-height: 12px;
		}
	}
}

.unvisible {
	display: none;
}

.checkbox {
	line-height: 16px;
	label {
		color: #777777;
	}
}

.close {
	opacity: 1;
	&:hover {
		opacity: 1;
	}
}

/* ----------------------------------------------------------------------- 
								BUTTONS
------------------------------------------------------------------------- */

.button_mini,
.button_small,
.button,
.button_large,
.button_mini_disabled,
.button_small_disabled,
.button_disabled,
.button_large_disabled,
.exclusive_mini,
.exclusive_small,
.exclusive,
.exclusive_large,
.exclusive_mini_disabled,
.exclusive_small_disabled,
.exclusive_disabled,
.exclusive_large_disabled {
	position: relative;
	display: inline-block;
	padding: 5px 7px;
	border: 1px solid #cc9900;
	font-weight: bold;
	color: black;
	background: url(../img/bg_bt.gif) repeat-x 0 0 #f4b61b;
	cursor: pointer;
	white-space: normal;
	text-align: left;
}

*:first-child+html input.button_mini,
*:first-child+html input.button_small,
*:first-child+html input.button,
*:first-child+html input.button_large,
*:first-child+html input.button_mini_disabled,
*:first-child+html input.button_small_disabled,
*:first-child+html input.button_disabled,
*:first-child+html input.button_large_disabled,
*:first-child+html input.exclusive_mini,
*:first-child+html input.exclusive_small,
*:first-child+html input.exclusive,
*:first-child+html input.exclusive_large,
*:first-child+html input.exclusive_mini_disabled,
*:first-child+html input.exclusive_small_disabled,
*:first-child+html input.exclusive_disabled,
*:first-child+html input.exclusive_large_disabled {
	border: none;
}

/* ------------------------------------------------------------------
				 		hover button 
--------------------------------------------------------------------- */

.button_mini:hover,
.button_small:hover,
.button:hover,
.button_large:hover,
.exclusive_mini:hover,
.exclusive_small:hover,
.exclusive:hover,
.exclusive_large:hover {
	text-decoration: none;
	background-position: left -50px;
}

//active button
.button_mini:active,
.button_small:active,
.button:active,
.button_large:active,
.exclusive_mini:active,
.exclusive_small:active,
.exclusive:active,
.exclusive_large:active {
	background-position: left -100px;
}

input.button_disabled,
input.exclusive_disabled,
span.exclusive {
	border: 1px solid #cccccc;
	color: #999999;
	background: url(../img/bg_bt_2.gif) repeat-x 0 0 #cccccc;
	cursor: default;
}

.btn.disabled,
.btn[disabled]:hover {
	opacity: 0.3;
}

.btn.btn-default {
	white-space:normal;
	font: normal 16px/22px $font-family !important;
	text-transform:uppercase;
	@include transition (.3s);
	border-width:2px;
	text-align:left;
	&.icon-right {
		span {
			display:block;
			padding-right:15px;
			position:relative;
			&:after {
				@include addIcon2('\e11d');
				position:absolute;
				font-size:24px;
				top:50%;
				right:-9px;
				height:30px;
				line-height:30px;
				margin-top:-15px;
				vertical-align:middle;
			}
		}
	}
	&.icon-left {
		span {
			display:block;
			padding-left:15px;
			position:relative;
			&:before {
				@include addIcon2('\e0c7');
				font-size:24px;
				position:absolute;
				top:50%;
				left:-9px;
				height:30px;
				line-height:30px;
				margin-top:-15px;
				vertical-align:middle;
			}
		}
	}
	span {
		i {
			&.left {
				vertical-align: 0;
				margin: 0 5px 0 0;
				display: inline-block;
			}
			&.right {
				margin-right: 0;
				margin-left: 5px;
			}
		}
	}
	&:hover,
	&:active,
	&:active:focus,
	&:focus {
		color:$btn-default-hover-color;
		background:$btn-default-hover-bg;
		border-color:$btn-default-hover-border;
		box-shadow:none;
		outline:none;
	}
}

.btn-sm, .btn-group-sm > .btn {
	font-size:14px !important;
}

.btn.ajax_add_to_cart_button, .btn.ajax_add_to_cart_product_button {
	text-align:center;
	&:before {
		@include addIcon2('\e14d');
		font-size:24px;
		line-height:20px;
		vertical-align:-3px;
		margin-right:5px;
	}
}

.btn.lnk_view {
	color: $base-text-color;
	padding: $homepage-padding-btn-vertical $homepage-padding-btn-horizontal;
	i.left {
		padding-right: 8px;
		color: #777777;
	}
}

.btn.btn-sm {
	span {
		i {
			vertical-align: 0;
			margin-right: 5px;
			&.right {
				margin-right: 0;
				margin-left: 5px;
			}
		}
	}
}

.link-button {
	font-weight: bold;
	i {
		margin-right: 5px;
		&.large {
			font-size: 26px;
			line-height: 26px;
			vertical-align: -3px;
			color: silver;
		}
	}
}

.btn.button-plus,
.btn.button-minus {
	font-size: 14px;
	line-height: 14px;
	color: $button-pm-color;
	text-shadow: 1px -1px rgba(0, 0, 0, 0.05);
	padding: 0;
	border: 1px solid;
	border-color: #dedcdc #c1bfbf #b5b4b4 #dad8d8;
	@include border-radius(0);
	span {
		display: block;
		border: 1px solid $light-border-color;
		vertical-align: middle;
		width: 25px;
		height: 25px;
		text-align: center;
		vertical-align: middle;
		padding: 4px 0 0 0;
		background: rgb(30, 87, 153);
		background: rgb(255, 255, 255);
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(251, 251, 251, 1) 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(251, 251, 251, 1)));
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(251, 251, 251, 1) 100%);
		background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(251, 251, 251, 1) 100%);
		background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(251, 251, 251, 1) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(251, 251, 251, 1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#fbfbfb',GradientType=0);
	}
	&:hover, &:active, &:focus {
		color: #555;
		border-color: #dedcdc #c1bfbf #b5b4b4 #dad8d8;
		span {
			filter: none;
			background: #f6f6f6;
			color: #555;
		}
	}
}


/* ----------------------------------------------------------------------
									FORM
------------------------------------------------------------------------- */

.form-control {
	padding: 3px 5px;
	height: 27px;
	@include box-shadow(none);
	&.grey {}
	&:active,
	&:focus {
		@include box-shadow(rgba(0,0,0,0) 0 0);
		border-color:darken($base-border-color, 30);	
	}
}

/* ----------------------------------------------------------------------- 
								TABLE
-------------------------------------------------------------------------- */

table.std,
table.table_block {
	margin-bottom: 20px;
	width: 100%;
	border: 1px solid #999999;
	border-bottom: none;
	background: white;
	border-collapse: inherit;
}

table.std th,
table.table_block th {
	padding: 14px 12px;
	font-size: 12px;
	color: $light-text-color;
	font-weight: bold;
	text-transform: uppercase;
	text-shadow: 0 1px 0 black;
	background: #999999;
}

table.std tr.alternate_item,
table.table_block tr.alternate_item {
	background-color: #f3f3f3;
}

table.std td,
table.table_block td {
	padding: 12px;
	border-right: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
	font-size: 12px;
	vertical-align: top;
}
body table th {
	font: normal 11px/22px $font-custom;
	text-transform:uppercase;
}
.table {
	margin-bottom: 30px;
	> thead {
		> tr {
			> th {
				@media only screen and (max-width:$screen-xs-max){
					padding-left:8px;
				}
				background: $table-background;
				border-bottom-width: 1px;
				color: $base-text-color;
				vertical-align: middle;
			}
		}
	}
	td {
		@media only screen and (max-width:$screen-xs-max){
			padding-left:8px !important;
		}
		a.color-myaccount {
			color: $link-color;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
	tfoot {
		tr {
			background: $table-background;
		}
	}
}

/* -------------------------------------------------------------------- 
							PRODUCT ELEMENTS 
----------------------------------------------------------------------- */

.product-name {
	font: 300 14px / 22px $font-family;
	color: $product-name-hover-color;
	margin-bottom: 0;
	text-transform:uppercase;
	a {
		font: 300 14px / 22px $font-family;
		color: $product-name-hover-color;
		text-transform:uppercase;
		&:hover {
			color: $product-name-color;
		}
	}
}
a.product-name {
	font: 300 14px / 22px $font-family;
	color: $product-name-hover-color;
	margin-bottom: 0;
	text-transform:uppercase;
	&:hover {
		color: $product-name-color;
	}
}

.price {
	font: normal 14px / 22px $font-custom;
	color: $price-color;
	white-space: nowrap;
}

.price.product-price {
	font: normal 16px / 26px $font-custom;
	color: $product-price-color;
}

.old-price {
	color: $product-old-price-color;
	text-decoration: line-through;
}

.old-price.product-price {
	font: 300 14px / 26px $font-family;
}

.special-price {
	color: $product-special-price-color;
}

.price-percent-reduction {
	background: #333;
	border: none;
	font: normal 14px / 26px $font-custom;
	color: $light-text-color;
	padding: 0 5px;
	display: inline-block;
	text-align:center;
	vertical-align:1px;
	&.small {
		font: normal 12px / 22px $font-custom;
	}
}

/* ------------------------------------------------------------------------
			 				Labels New/Sale 
--------------------------------------------------------------------------- */

.new-sale-box {
	position: absolute;
	top: 0;
	left:0;
	text-align: center;
	z-index: 0;
	background:rgba(51,51,51,0.8);
	width:100%;
}
.sale-box, .new-box {
	display:inline-block;
	padding:9px 0;
	@media (max-width: $screen-md-max) { // max 1199px
		padding:2px 0;
	}
}
.new-label {
	font: normal 14px / 13px $font-custom;
	color: $light-text-color;
	text-transform: uppercase;
	display: inline-block;
	@media (max-width: $screen-md-max) { // max 1199px
		font: normal 11px / 11px $font-custom;
	}
}

.sale-label {
	font: normal 14px / 13px $font-custom;
	color: $light-text-color;
	text-transform: uppercase;
	display: inline-block;
	@media (max-width: $screen-md-max) { // max 1199px
		font: normal 11px / 11px $font-custom;
	}
	&.rightBorder {
		border-right:1px solid #ededed;
		padding-right:10px;
		margin-right:10px;
		@media (max-width: $screen-md-max) { // max 1199px
			padding-right:3px;
			margin-right:3px;
		}
	}
}

.lang_ru {
	.sale-label {
		font-size:10px;	
	}
}

.ie8 {
	.new-label {
		left: 0;
		top: 0;
		width: auto;
		padding: 5px 15px;
		&:after {
			display: none;
		}
		&:before {
			display: none;
		}
	}
	.sale-label {
		right: 0;
		top: 0;
		width: auto;
		padding: 5px 15px;
		&:after {
			display: none;
		}
		&:before {
			display: none;
		}
	}
	.new-box {
		top: -1px;
		left: -1px;
	}
	.sale-box {
		right: -1px;
		top: -1px;
	}
}

/* ---------------------------------------------------------------------------------
									BOXES 
------------------------------------------------------------------------------------ */

.box {
	background: $base-box-bg;
	border: 1px solid $base-border-color;
	padding: $base-box-padding;
	margin: $base-box-indent;
	line-height: 23px;
	p {
		margin-bottom: 0;
	}
	&.box-small {
		padding: 9px 10px 9px 20px;
		.dark {
			padding-top: 10px;
			padding-right: 20px;
			margin-bottom: 0;
			width: auto;
		}
	}
}

.page-product-box {
	padding-bottom: 10px;
}

.product-box {
	width: 178px;
	float: left;
	@include box-sizing(border-box);
	.product-image {
		@include product-image;
		padding: 5px;
		display: block;
		margin-bottom: 11px;
		img {
			max-width: 100%;
			width: 100%;
			height: auto;
		}
	}
}

/* ----------------------------------------------------------------------------- 
								Homepage Tabs 
-------------------------------------------------------------------------------- */

#home-page-tabs {
	border: none;
	margin:0 auto;
	background: $light-background;
	text-align:center;
	> li {
		margin-bottom: 0;
		display:inline-block;
		float:none;
		@media (max-width: $screen-xs - 1) { // max 479px
			width: 100%;
		}
		a {
			font: 600 19px / 22px $font-custom;
			color: $base-heading-color;
			text-transform: uppercase;
			border: none;
			outline: none;
			margin: 0;
			padding: 10px;
		}
	}
	> li.active a,
	>li a:hover {
		background: $dark-background;
		color: $light-text-color;
		padding: 10px;
	}
}

.block {
	margin-bottom: 30px;
	@media (max-width: $screen-xs-max) { // max 767px
		margin-bottom: 0;
	}
	.block_content {
		> ul {
			margin-bottom:0;	
		}
		@media (max-width: $screen-xs-max) { // max 767px
			margin-bottom: 20px;
		}
	}
}

.block {
	.title_block,
	h4 {
		font: normal 25px / 30px $font-custom;
		color: $base-heading-color;
		background: none;
		border-bottom: 5px solid $dark-border-color;
		text-transform: uppercase;
		padding: 10px 10px 14px 0;
		margin:0 0 30px 0;
		@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
			font: normal 16px / 22px $font-custom;
		}
		@media (max-width: $screen-xs-max) { // max 767px
			position: relative;
			padding-right:40px;
			&:after {
				display: block;
				font-family: $font-icon;
				content: "";
				position: absolute;
				right: 0;
				top: 15px;
				height: 36px;
				width: 36px;
				font-size: 26px;
				font-weight: normal;
			}
			&.active:after {
				content: "";
			}
		}
		a {
			color: $base-heading-color;
			&:hover {
				color: $base-body-color;
			}
		}
	}
	.list-block {
		margin-top: -8px;
		@media (max-width: $screen-xs-max) { // max 767px
			margin-top: 0;
		}
		li {
			padding: 5px 0 6px 20px;
			border-top: 1px solid $base-border-color;
			a {
				i {
					display: none;
				}
				&:before {
					content: "\f105";
					display: inline;
					font-family: $font-icon;
					color: $base-text-color;
					padding-right: 8px;
				}
				&:hover {
					color: $link-hover-color;
				}
			}
			&:first-child {
				border-top: none;
			}
		}
		.form-group {
			padding-top: 20px;
			border-top: 1px solid $base-border-color;
			margin-bottom: 0;
			select {
				max-width: 157px;
				width:157px;
			}
		}
		.btn {
			margin-top: 12px;
		}
	}
	.products-block {
		li {
			padding: 0 0 20px 0;
			margin-bottom: 20px;
			border-bottom: 1px solid $base-border-color;
			.products-block-image {
				@include products-block-image;
				float: left;
				margin-right: 19px;
				@media (min-width: $screen-sm) and (max-width: $screen-sm-max) { // min 768px max 991px
					float: none;
					display: inline-block;
					margin: 0 auto 10px;
					text-align: center;
				}
			}
			.product-content {
				overflow: hidden;
				h5 {
					margin: 0 0 5px;
				}
			}
		}
		.product-description {
			margin-bottom: 14px;
		}
		.price-percent-reduction {
			font: normal 12px / 20px $font-custom;
			border:1px solid #333;
			background:none;
			color:#333;
			margin:0 3px;
		}
	}
}

.page-heading {
	font: normal 25px / 30px $font-custom;
	color: $base-heading-color;
	background: none;
	border-bottom: 5px solid $dark-border-color;
	text-transform: uppercase;
	padding: 10px 10px 14px 0;
	margin:0 0 30px 0;
	@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
		font: normal 16px / 22px $font-custom;
	}
	span.heading-counter {
		font: normal 12px / 22px $font-family;
		float: right;
		color: $base-body-color;
		text-transform: none;
		margin-bottom: 10px;
		text-transform:uppercase;
		padding-top:8px;
		@media (max-width: $screen-xs) {
			float: none;
			display: block;
			padding-top: 5px;
		}
	}
	span.lighter {
		color: #9c9c9c;
	}
	&.bottom-indent {
		margin-bottom: 16px;
	}
}

.page-subheading {
	font-family: $font-custom;
	font-weight: normal;
	text-transform: uppercase;
	color: $base-heading-color;
	font-size: 18px;
	padding: 0 0 15px;
	line-height: normal;
	margin: 0 0 12px;
	border-bottom: 1px solid $base-border-color;
}

h3.page-product-heading {
	color: $base-heading-color;
	text-transform: uppercase;
	font-family: $font-custom;
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	padding: 14px 0px 0px;
	margin: 0 0 20px;
	position: relative;
}

/* ----------------------------------------------------------------------------------------------
							 BLOCK .footer_links (cms pages) 
------------------------------------------------------------------------------------------------- */

ul.footer_links {
	padding: 20px 0 0 0;
	border-top: 1px solid $base-border-color;
	height: 65px;
	li {
		float: left;
		margin-bottom:10px;
		&+li {
			margin-left: 10px;
		}
	}
}

/* -----------------------------------------------------------------------------------------------
						 barre comparaison / tri 
-------------------------------------------------------------------------------------------------- */

.content_sortPagiBar {
	.sortPagiBar {
		border: 2px solid #e9e9e9;
		clear: both;
		#productsSortForm {
			float: left;
			border-right: 1px solid $base-border-color;
			padding:7px 17px 9px;
			@media (max-width: $screen-xs-max) { // max 767px
				border:none;
			}
			select {
				max-width: 192px;
				float: left;
				@media (max-width: $screen-sm-max) {
					max-width: 160px;
				}
			}
			.selector {
				float: left;
				border:none;
				padding-top:2px;
				span:after {
					border:none;
					background:none;
					content: "\e071";
					font-family: $flat-icon;
					font-size:24px;
					line-height:24px;
				}
			}
		}
		.nbrItemPage {
			float: left;
			padding:7px 17px 9px;
			@media (max-width: $screen-lg + 380) { // max 1580
				border-top:1px solid #e9e9e9;
				width:100%;
			}
			select {
				max-width: 89px;
				width:89px;
				float: left;
			}
			.clearfix > span {
				padding:10px 19px 14px;
				margin-top:-7px;
				margin-bottom:-9px;
				margin-left:9px;
				color:#333;
				display:block;
				text-transform:uppercase;
				float: left;
				border-left: 1px solid $base-border-color;
				@media (max-width: $screen-xs - 1) { // max 479px
					padding:10px 0 14px 5px;
				}
			}
			.selector {
				float: left;
				border:none;
				padding-top:2px;
				span:after {
					border:none;
					background:none;
					content: "\e071";
					font-family: $flat-icon;
					font-size:24px;
					line-height:24px;
				}
			}
		}
		label,
		select {
			float: left;
		}
		label {
			padding: 3px 6px 0 0;
		}
		&.instant_search {
			#productsSortForm {
				display: none;
			}
		}
	}
	.display,
	.display_m {
		float: right;
		padding:11px 19px 7px;
		margin-bottom:0;
		li {
			float: left;
			padding-left: 4px;
			text-align: center;
			a {
				color: $GL-icon-color;
				font-size: 24px;
				line-height: 24px;
				cursor: pointer;
				&:hover {
					&:before {
						color:$GL-icon-color-hover;	
					}
				}
				span {
					display:none;	
				}
			}
			&#grid {
				a:before {
					content:"\e180";
					font-family:$flat-icon;	
				}
			}
			&#list {
				a:before {
					content:"\e190";
					font-family:$flat-icon;	
				}
			}
			&.selected {
				a {
					cursor: default;
					&:before {
						color:$GL-icon-color-hover;	
					}
				}
			}
			&.display-title {
				text-transform:uppercase;
				color: $base-text-color;
				padding: 0 4px 0 0;
			}
		}
	}
}

/* -------------------------------------------------------------------------------------------------------
											 pagination
---------------------------------------------------------------------------------------------------------- */

.top-pagination-content,
.bottom-pagination-content {
	text-align: center;
	padding: 20px 0;
	position: relative;
	div.pagination {
		margin: 10px 0 0;
		@media (max-width: $screen-lg + 380) { // max 1580
			float:right;
		}
		.showall {
			float: right;
			margin:0;
			@media (min-width: $screen-md) and (max-width: $screen-md-max) { // min 992px max 1199px

			}
			@media (max-width: $screen-sm-max) { // max 991px

			}
			.btn.btn-default {
				padding:0;
				margin: 8px 14px;
				background:none;
				color:#333;
				border:none;
				font-family:$font-family;
				font-size:12px;
				line-height:22px;
				font-weight:300;
				&:hover {
					color:#000;
				}
			}
		}
	}
	ul.pagination {
		margin: 0 0 8px 0;
		float:right;
		li {
			border:1px solid #e9e9e9;
			display:inline-block;
			height:40px;
			width:40px;
			text-align:center;
			margin:0 3px 0 4px;
			vertical-align:bottom;
			@media (max-width: $screen-xs - 1) { // max 479px
				margin:0;
			}
			> a,
			> span {
				border: none !important;
				background: none !important;
				display: block;
				color:#aeaeae !important;
				margin:0 !important;
				line-height:36px;
				font-size:14px;
				padding:0;
				text-align:center;
				float:none;
				b {
					display: none;
				}
				span {
					border: none;
					padding: 0;
					background: none;
					display: block;
				}
			}
			> span span { color:#262626 !important;}
			> a:hover { color:#262626 !important;}
			&.pagination_previous,
			&.pagination_next {
				-webkit-box-shadow: inset 0 0 0 1px #e9e9e9;
				-moz-box-shadow: inset 0 0 0 1px #e9e9e9;
				box-shadow: inset 0 0 0 1px #e9e9e9;
				> a,
				> span {
					color: #262626 !important;
					font-size:32px;
					i {
						display:none;	
					}
					&:before {
						font-family:$flat-icon;
						content:"\e0c2";
					}
				}
				> a:hover {
					color:#000 !important;	
				}
				> span {
					color:#888 !important;	
				}
			}
			&.pagination_previous {
				> a,
				> span {
					&:before {
						content:"\e0c3";
					}
				}
			}
		}
	}
	.compare-form {
		float: right;
		margin-left:5px;
		margin-bottom:10px;
		.bt_compare {
			padding-left:31px;
			padding-right:31px;
			strong {
				font-weight:normal;
				margin-left:20px;
			}
		}
	}
	.product-count {
		padding: 20px 30px 5px 0;
		float: left;
		text-transform:uppercase;
		@media (max-width: $screen-lg + 380) { // max 1580
			float:none;
			clear:both;
			width:100%;
		}
	}
}

.bottom-pagination-content {
	border-top: 1px solid $base-border-color;
}

/* -----------------------------------------------------------------------------------------------------------
											 CART steps 
-------------------------------------------------------------------------------------------------------------- */

ul.step {
	margin-bottom: 30px;
	overflow: hidden;
	@media (min-width: $screen-xs) { //min 480px
		@include border-radius($cs-border-radius);
	}
	li {
		float: left;
		width: 20%;
		text-align: left;
		border: 1px solid;
		border-top-color: $step-todo-border-color;
		border-bottom-color: $step-todo-border-color;
		border-right-color: $step-todo-border-color;
		border-left-width: 0;
		@media (max-width: $screen-xs-max) { // max 480px
			width: 100%;
			border-left-width: 1px;
		}
		a,
		span,
		&.step_current span,
		&.step_current_end span {
			display: block;
			padding: 13px 10px 14px 13px;
			color: $base-text-color;
			font-size: 17px;
			line-height: 21px;
			font-weight: bold;
			text-shadow: 1px 1px rgba(255, 255, 255, 1);
			position: relative;
			@media (max-width: $screen-md) { // max 991px
				font-size: 15px;
			}
			@media (min-width: $screen-md) { // min 991px
				&:after {
					content: ".";
					position: absolute;
					top: 6px;
					right: -18px;
					z-index: 0;
					text-indent: -5000px;
					display: block;
					width: 36px;
					height: 36px;
					margin-top: 0;
					@include rotate(45deg);
				}
			}
			&:focus {
				text-decoration: none;
				outline: none;
			}
		}
		&.first {
			border-left-width: 1px;
			border-left-color: #b7b7b7;
			@media (min-width: $screen-xs) { //min 480px
				@include border-top-left-radius($cs-border-radius);
				@include border-bottom-left-radius($cs-border-radius);
			}
			span,
			a {
				z-index: 5;
				padding-left: 13px !important;
				@media (min-width: $screen-xs) { //min 480px
					@include border-top-left-radius($cs-border-radius);
					@include border-bottom-left-radius($cs-border-radius);
				}
			}
		}
		&.second {
			span,
			a {
				z-index: 4;
			}
		}
		&.third {
			span,
			a {
				z-index: 3;
			}
		}
		&.four {
			span,
			a {
				z-index: 2;
			}
		}
		&.last {
			span {
				z-index: 1;
			}
			@media (min-width: $screen-xs) { //min 480px
				@include border-top-right-radius($cs-border-radius);
				@include border-bottom-right-radius($cs-border-radius);
				span {
					@include border-top-right-radius($cs-border-radius);
					@include border-bottom-right-radius($cs-border-radius);
				}
			}
		}
		&.step_current {
			font-weight: bold;
			background:$step-current-bg;
			border-color: $step-current-border-color;
			span {
				color: $light-text-color;
				text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
				position: relative;
				@media (min-width: $screen-md) { //min 991px
					padding-left: 38px;
					&:after {
						background:$step-current-bg;
						border-top:1px solid $step-current-border-color;
						border-right:1px solid $step-current-border-color;
					}
				}
			}
		}
		&.step_current_end {
			font-weight: bold;
		}
		&.step_todo {
			background:$step-todo-bg;
			span {
				display: block;
				color: $base-text-color;
				position: relative;
				@media (min-width: $screen-md) { // min 991px
					padding-left: 38px;
					&:after {
						background:$step-todo-bg;
						border-top:1px solid $step-todo-border-color;
						border-right:1px solid $step-todo-border-color;
					}
				}
			}
		}
		&.step_done {
			border-color: $step-done-border-color;
			background:$step-done-bg;
			a {
				color: $light-text-color;
				text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
				@media (min-width: $screen-md) { // min 991px
					padding-left: 38px;
					&:after {
						background:$step-done-bg;
						border-top:1px solid $step-done-border-color;
						border-right:1px solid $step-done-border-color;
					}
				}
			}
		}
		&.step_done.step_done_last {
			@media (min-width: $screen-md) {
				a:after {
					background:$step-done-bg;
					border-top:1px solid $step-done-border-color;
					border-right:1px solid $step-done-border-color;
				}
			}
		}
		&#step_end {
			span {
				@media (min-width: $screen-md) {
					&:after {
						display: none;
					}
				}
			}
		}
		em {
			font-style: normal;
			@media (min-width: $screen-xs) and (max-width: $screen-xs-max) { // min 480px max 767px
				display: none;
			}
		}
	}
	a {
		&:hover {
			background:$step-hover-bg;
			border-color:$step-hover-border-color;
			&:after {
				border-top-color:$step-hover-border-color!important;
				border-right-color:$step-hover-border-color!important;
				background:$step-hover-bg !important;
			}
		}
	}
}
.one-column {
	ul.step {
		li {
			em { 
				display:inline;
				@media (max-width: $screen-md-max) {
					display:none;
				}
			}
			a,
			span,
			&.step_current span,
			&.step_current_end span {
				font-size:17px;
				line-height:21px;
				padding-top:13px;
				padding-bottom:14px;
				@media (max-width: $screen-md-max) {
					padding-left:15px;
					font-size:14px;
				}
				&:after {
					height: 36px;
					right: -18px;
					top: 6px;
					width: 36px;
					@media (max-width: $screen-md-max) {
						display:none;
					}
				}
			}
		}
	}
}
.two-columns {
	ul.step {
		li {
			em { display:none;}
			a,
			span,
			&.step_current span,
			&.step_current_end span {
				font-size:12px;
				line-height:17px;
				padding-top:13px;
				padding-bottom:14px;
				&:after {
					height: 32px;
					right: -17px;
					top: 6px;
					width: 32px;
				}
			}
		}
	}
	
	#order-detail-content.table-responsive {
		@media only screen and (max-width:$screen-sm-max) {
			overflow-x:scroll;
		}	
	}
}
.three-columns {
	ul.step {
		li {
			width:100%;
			border-top-width:0;
			&:first-child {
				border-top-width:1px;	
			}
			em { display:none;}
			a,
			span,
			&.step_current span,
			&.step_current_end span {
				font-size:13px;
				line-height:17px;
				padding-top:13px;
				padding-bottom:14px;
				padding-left:15px;
				&:after {
					display:none;
				}
			}
		}
	}
}

.ie8 {
	ul.step {
		li {
			a,
			span,
			&.step_current span,
			&.step_current_end span {
				&:after {
					display:none;	
				}
			}
		}
	}
}

/* -------------------------------------------------------------------------------------------------------
							 module productcomments
---------------------------------------------------------------------------------------------------------- */

.rating {
	clear: both;
	display: block;
	margin: 2em;
	cursor: pointer;
}

.rating:after {
	content: ".";
	display: block;
	height: 0;
	width: 0;
	clear: both;
	visibility: hidden;
}

.cancel,
.star {
	overflow: hidden;
	float: left;
	margin: 0 1px 0 0;
	width: 16px;
	height: 16px;
	cursor: pointer;
}

.cancel, .cancel a {
	background: url(../../../modules/productcomments/img/delete.gif) no-repeat 0 -16px !important;
}

.cancel a,
.star a {
	display: block;
	width: 100%;
	height: 100%;
	background-position: 0 0;
}

div.star_on a {
	background-position: 0 -16px;
}

div.star_hover a,
div.star a:hover {
	background-position: 0 -32px;
}

.pack_content {
	margin: 10px 0 10px 0;
}

.confirmation {
	margin: 0 0 10px;
	padding: 10px;
	border: 1px solid #e6db55;
	font-size: 13px;
	background: none repeat scroll 0 0 lightyellow;
}

#page .rte {
	background: transparent none repeat scroll 0 0;
}

.listcomment {
	list-style-type: none;
	margin: 0 0 20px 0 !important;
}

.listcomment li {
	padding: 10px 0;
	border-bottom: 1px dotted $base-border-color;
	color: #666666;
}

.listcomment .titlecomment {
	display: block;
	font-weight: bold;
	font-size: 12px;
	color: $base-text-color;
}

.listcomment .txtcomment {
	display: block;
	padding: 5px 0;
	color: $base-text-color;
}

.header-container {
	background: $light-background;
}

/* ------------------------------------------------------------------------------------------------
									 BREADCRUMB 
--------------------------------------------------------------------------------------------------- */

.breadcrumb {
	display: block;
	/*
	text-align:center;
	padding: 23px 0 6px;
	*/
	@include border-radius(0);
	overflow: hidden;
	margin-bottom: 34px;
	position: relative;
	background: none;
	z-index: 1;
	color: #333;
	text-transform:uppercase;
	margin-bottom:12px;
	.navigation-pipe {
		margin:0 17px;
		display:inline-block;
		color:#888;
		text-indent:-5555px;
		height:11px;
		border-left:1px solid #f1f1f1;
		border-right:1px solid #9b9b9b;
		vertical-align:middle;
	}
	a {
		display: inline-block;
		padding: 0;
		color: $base-text-color;
		color:#888;
		&.home {
			font-size: 16px;
			i {
				vertical-align:-1px;
			}
		}
		&:hover {
			color: #333;
		}
	}
}

.ie8 {
	.breadcrumb {
		min-height: 1px;
		a:after {
			display: none;
		}
		.navigation-pipe {
			width: 20px;
		}
	}
}

/* ---------------------------------------------------------------------------------------------------- 
											FOOTER 
------------------------------------------------------------------------------------------------------- */

/*
#footer {
	color: #888;
	padding-top:47px;
	background:#fff;
	overflow:hidden;
	.row {
		position: relative;
	}
	.footer-block {
		margin-top: 45px;
		@media (max-width: $screen-xs-max) { // max 767px
			margin-top: 20px;
		}
	}
	a {
		color: #888;
		&:hover {
			color:$link-hover-color;
		}
	}
	h4 {
		font: 600 18px / 22px $font-custom;
		color: $light-text-color;
		margin: 0 0 13px 0;
		@media (max-width: $screen-xs-max) { // max 767px
			position: relative;
			margin-bottom: 0;
			padding-bottom: 13px;
			padding-right:25px;
			&:after {
				display: block;
				content: "\f055";
				font-family: $font-icon;
				position: absolute;
				right: 0;
				top: 1px;
			}
			&.active:after {
				content: "\f056";
			}
		}
		a {
			color: #888;
			
			&:hover {
				color:$link-hover-color;	
			}
		}
	}
	ul {
		margin-bottom:0;
		li {
			padding-bottom: 9px;
			a {
				
			}
		}
	}
	#block_contact_infos {
		border-left: 1px solid #515151;
		@media (max-width: $screen-xs-max) { // max 767px
			border: none;
		}
		> div {
			padding: 0 0 0 5px;
			@media (max-width: $screen-xs-max) { // max 767px
				padding-left: 0;
			}
			ul {
				li {
					padding: 0 0 7px 4px;
					overflow: hidden;
					line-height: 30px;
					> span,
					> span a {
						color: $light-text-color;
						font-weight: normal;
					}
					i {
						font-size: 25px;
						width: 32px;
						text-align: center;
						padding-right: 12px;
						float: left;
						color: #908f8f;
					}
				}
			}
		}
	}
	#block_various_links_footer {
		display:none;	
	}
	#social_block {
		padding:0 30px;
		h4 { display:none;}
		ul {
			li {
				float: left;
				width:27px;
				a {
					display: inline-block;
					color: #aeaeae;
					font-size: 17px;
					font-weight:normal;
					span {
						display: none;
					}
					&:before {
						display: inline-block;
						font-family: $font-icon;
					}
					&:hover {
						color: #333;
					}
				}
				&.facebook {
					a {
						&:before {
							content: "\f09a";
						}
					}
				}
				&.twitter {
					a {
						&:before {
							content: "\f099";
						}
					}
				}
				&.rss {
					a {
						&:before {
							content: "\f09e";
						}
					}
				}
				&.youtube {
					a {
						&:before {
							content: "\f167";
						}
					}
				}
				&.google-plus {
					a {
						&:before {
							content: "\f0d5";
						}
					}
				}
				&.pinterest {
					a {
						&:before {
							content: "\f0d2";
						}
					}
				}
				&.vimeo {
					a {
						&:before {
							content: "\f194";	
						}
					}
				}
				&.instagram {
					a {
						&:before {
							content: "\f16d";	
						}
					}
				}
			}
		}
	}
	.bottom-footer {
		padding:0 20px 20px 30px;
		div {
			
		}
	}
}
*/
#reinsurance_block {
	clear: both !important;
}

/* ------- ---------------------------------------------------------------------------------------------------------------------
					Make sure lists are correctly displayed in tinyMCE BO edition mode too 
-------------------------------------------------------------------------------------------------------------------------------- */

#short_description_content ul,
#short_description_content ol,
#short_description_content dl,
#tinymce ul,
#tinymce ol,
#tinymce dl {
	margin-left: 20px;
}

.rte ul {
	list-style-type: disc;
}

.rte ol {
	list-style-type: decimal;
}

.block_hidden_only_for_screen {
	display: none;
}

/* ----------------------------------------------------------------------------------------------------------
										 PAIMENT - PAGE ORDER 
------------------------------------------------------------------------------------------------------------- */

/* ------------------------------------------------------------------------------------------------------------
											nav bottom 
--------------------------------------------------------------------------------------------------------------- */

.cart_navigation {
	margin: 0 0 20px;
	.btn-md {
		float: right;
	}
	.btn {
		margin-bottom:10px;	
	}
	@media (max-width: $screen-xs) {
		> span {
			display: block;
			width: 100%;
			padding-bottom: 15px;
		}
	}
}

/* ---------------------------------------------------------------------------------------------------------------------
												 step 1 - cart 
------------------------------------------------------------------------------------------------------------------------ */

.cart_last_product {
	display: none;
}

.cart_quantity .cart_quantity_input {
	height: 27px;
	line-height: 27px;
	padding: 0;
	text-align: center;
	width: 57px;
}

.cart_gift_quantity .cart_quantity_input {
	height: 27px;
	line-height: 27px;
	padding: 0;
	text-align: center;
	width: 57px;
}

.table {
	tbody > tr > td {
		vertical-align: middle;
		&.cart_quantity {
			padding-left:14px;
			width: 88px;
			.cart_quantity_button {
				margin-top: 3px;
				display:block !important;
				a {
					float: left;
					margin-right: 3px;
					&+ a {
						margin-right: 0;
					}
				}
			}
		}
		&.cart_delete,
		&.price_discount_del {
			padding: 5px;
		}
	}
	tfoot > tr > td {
		vertical-align: middle;
	}
}

.cart_delete a.cart_quantity_delete,
a.price_discount_delete {
	font-size: 23px;
	color: $base-text-color;
	&:hover {
		color: silver;
	}
}

#cart_summary {
	border-left:none;
	border-bottom:none;
	tbody {
		td {
			&.cart_product {
				padding: 19px;
				width: 137px;
				img {
					@include products-block-image;
				}
			}
			&.cart_unit {
				.price {
					span {
						display: block;
						&.price-percent-reduction {
							margin: 5px auto;
							display: inline-block;
						}
						&.old-price {
							text-decoration: line-through;
						}
					}
				}
			}
			&.cart_description {
				width: 480px;
				small {
					display: block;
					padding: 5px 0 0 0;
				}
			}
			&.cart_avail {}
		}
	}
	tfoot {
		tr {
			border-bottom:none;	
		}
		td {
			&.text-right {
				font-weight: bold;
				color: $base-text-color;
			}
			&.price {
				text-align: right;
			}
			&.total_price_container {
				span {
					font: normal 18px / 22px $font-custom;
					color: #555454;
					text-transform: uppercase;
				}
			}
			&#total_price_container {
				font: 600 21px / 25px $font-custom;
				color: $product-price-color;
				background: $light-background;
			}
		}
	}
}

@media (max-width: $screen-xs-max) {
	#order-detail-content {
		#cart_summary {
			table,
			thead,
			tbody,
			th,
			td,
			tr {
				display: block;
			}
			thead tr {
				position: absolute;
				top: -9999px;
				left: -9999px;
			}
			tr {
				border-bottom: 1px solid #cccccc;
				overflow: hidden;
			}
			td {
				border: none;
				position: relative;
				width: 50%;
				float: left;
				white-space: normal;
				&.cart_product {}
				&.cart_description {}
				&.cart_avail {
					clear: both;
				}
				&.cart_unit {}
				&.cart_quantity {
					clear: both;
					padding: 9px 8px 11px 18px;
				}
				&.cart_total {}
				&.cart_delete {
					width: 100%;
					clear: both;
					text-align: right;
					&:before {
						display: inline-block;
						padding-right: 0.5em;
						position: relative;
						top: -3px;
					}
				}
				div {
					display: inline;
				}
			}
			td:before {
				content: attr(data-title);
				display: block;
			}
			tfoot {
				td {
					float: none;
					width: 100%;
					&:before {
						display: inline;
					}
				}
				tr {
					.text-right, .price {
						display: block;
						float: left;
						width: 50%;
					}
				}
			}
		}
	}
}

@media (max-width: $screen-sm) {
	#order-detail-content {
		#cart_summary {
			tbody {
				td {
					.price {
						text-align: left;
					}
				}
			}
		}
	}
}

.cart_voucher {
	vertical-align: top !important;
	padding:0 !important;
	background:#fff;
	border-left:none !important;
	border-bottom:none !important;
	#voucher {
		padding:10px;	
	}
	h4 {
		font: 600 18px / 22px $font-custom;
		color: $base-heading-color;
		text-transform: uppercase;
		padding: 7px 0 10px 0;
	}
	.title-offers {
		color: $base-text-color;
		font-weight: bold;
		margin-bottom: 6px;
	}
	fieldset {
		margin-bottom: 10px;
		#discount_name {
			float: left;
			width: 219px;
			margin-right: 11px;
		}
	}
	#display_cart_vouchers {
		span {
			font-weight: bold;
			cursor: pointer;
			color: $link-color;
			&:hover {
				color: $link-hover-color;
			}
		}
	}
	#voucher {
		#discount_name {
			height:30px;	
		}
	}
}

.enable-multishipping {
	margin: -13px 0 17px;
	label {
		font-weight: normal;
	}
}

#HOOK_SHOPPING_CART {
	#loyalty {
		i {
			font-size: 26px;
			line-height: 26px;
			color: #cfcccc;
		}
	}
}

#order_carrier {
	clear: both;
	margin-top: 20px;
	border: 1px solid #999999;
	background: $light-background;
}

#order_carrier .title_block {
	padding: 0 15px;
	height: 29px;
	font-weight: bold;
	line-height: 29px;
	color: $light-text-color;
	font-weight: bold;
	text-transform: uppercase;
	background: url(../img/bg_table_th.png) repeat-x 0 -10px #999999;
}

#order_carrier span {
	display: block;
	padding: 15px;
	font-weight: bold;
}

.multishipping_close_container {
	text-align: center;
}

table#cart_summary .gift-icon {
	color: white;
	background: #0088cc;
	line-height: 20px;
	padding: 2px 5px;
	border-radius: 5px;
}

/* ----------------------------------------------------------------------------------------------------
											 step 3 - address 
------------------------------------------------------------------------------------------------------- */

#multishipping_mode_box {
	padding-top: 12px;
	padding-bottom: 19px;
	.title {
		font-weight: bold;
		color: $base-text-color;
		margin-bottom: 15px;
	}
	.description_off {
		display: none;
		div {
			margin-bottom: 10px;
		}
	}
	.description_off a:hover,
	.description a:hover {
		background: #f3f3f3;
		border: 1px solid #cccccc;
	}
}

#multishipping_mode_box.on .description_off {
	display: block;
}

.multishipping-cart {
	.cart_address_delivery {
		&.form-control {
			width: 198px;
		}
	}
}

#order .address_add.submit {
	margin-bottom: 20px;
}

#ordermsg {
	margin-bottom: 30px;
}

#id_address_invoice.form-control,
#id_address_delivery.form-control {
	width: 269px;
}

.addresses {
	.select label,
	.selector,
	.addresses .address_select {
		clear: both;
		float: left;
	}
	.waitimage {
		display: none;
		float: left;
		width: 24px;
		height: 24px;
		background-image: url("../../../img/loader.gif");
		background-repeat: no-repeat;
		position: relative;
		bottom: -2px;
		left: 10px;
	}
	.checkbox.addressesAreEquals {
		clear: both;
		float: left;
		margin: 15px 0 25px 0;
	}
}

/* -----------------------------------------------------------------------------------------------------------------------
								 				step 4 - paiement
-------------------------------------------------------------------------------------------------------------------------- */

#order .delivery_option,
#order-opc .delivery_option {
	> div {
		display: block;
		margin-bottom: 20px;
		font-weight: normal;
		> table {
			background: $light-background;
			margin-bottom: 0;
			&.resume {
				height: 53px;
				&.delivery_option_carrier {
					margin-top: 10px;
					width: 100%;
					background: $state-info-bg;
					color: $state-info-text;
					border: 1px solid $state-info-border;
					font-weight: bold;
					td {
						padding: 8px 11px 7px 11px;
						i {
							font-size: 20px;
							margin-right: 7px;
							vertical-align: -2px;
						}
					}
				}
				td {
					&.delivery_option_radio {
						width: 54px;
						padding-left: 0;
						padding-right: 0;
						text-align: center;
					}
					&.delivery_option_logo {
						width: 97px;
						padding-left: 21px;
					}
					&.delivery_option_price {
						width: 162px;
					}
				}
			}
		}
	}
}

.order_carrier_content {
	line-height: normal;
	padding-bottom: 16px;
	.carrier_title {
		font-weight: bold;
		color: $base-text-color;
	}
	.checkbox {
		input {
			margin-top: 2px;
		}
	}
	.delivery_options_address {
		.carrier_title {
			margin-bottom: 17px;
		}
	}
	& + div {
		padding-bottom: 15px;
	}
}

#carrierTable {
	border: 1px solid #999999;
	border-bottom: none;
	background: $light-background;
}

#carrierTable tbody {
	border-bottom: 1px solid #999999;
}

#carrierTable th {
	padding: 0 15px;
	height: 29px;
	font-weight: bold;
	line-height: 29px;
	color: $light-text-color;
	text-transform: uppercase;
	background: url(../img/bg_table_th.png) repeat-x 0 -10px #999999;
}

#carrierTable td {
	padding: 15px;
	font-weight: bold;
	border-right: 1px solid #e9e9e9;
}

#carrierTable td.carrier_price {
	border: none;
}

p.checkbox.gift {
	margin-bottom: 10px;
}

#gift_div {
	display: none;
	margin-top: 5px;
	margin-bottom: 10px;
}

a.iframe {
	color: $base-text-color;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}

/* -------------------------------------------------------------------------------------------------------------
										 step 5 - paiement 
---------------------------------------------------------------------------------------------------------------- */

p.payment_module {
	margin-bottom: 10px;
	a {
		display: block;
		border: 1px solid $table-border-color;
		@include border-radius(0);
		font-size: 17px;
		line-height: 23px;
		color: $base-text-color;
		font-weight: bold;
		padding: 33px 40px 34px 99px;
		letter-spacing: -1px;
		position: relative;
		&.bankwire {
			background: url(../img/bankwire.png) 15px 12px no-repeat $base-box-bg;
		}
		&.cheque {
			background: url(../img/cheque.png) 15px 15px no-repeat $base-box-bg;
		}
		&.cash {
			background: url(../img/cash.png) 15px 15px no-repeat $base-box-bg;
		}
		&.cheque,
		&.bankwire,
		&.cash {
			&:after {
				display: block;
				content: "\f054";
				position: absolute;
				right: 15px;
				margin-top: -11px;
				top: 50%;
				font-family: $font-icon;
				font-size: 25px;
				height: 22px;
				width: 14px;
				color: $link-color;
			}
		}
		&:hover {
			background-color: #f6f6f6;
		}
		span {
			font-weight: bold;
			color: $base-body-color;
		}
	}
}

.payment_module.box {
	margin-top: 20px;
}

p.cheque-indent {
	margin-bottom: 9px;
}

.cheque-box {
	.form-group {
		.form-control {
			width: 250px;
		}
	}
}

.order-confirmation.box {
	line-height: 29px;
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}

/* -----------------------------------------------------------------------------------------------------
									 PAGE ORDER-OPC
-------------------------------------------------------------------------------------------------------- */

.delivery_option_carrier td {
	width: 200px;
}

.delivery_option_carrier td + td {
	width: 280px;
}

.delivery_option_carrier td + td + td {
	width: 200px;
}

.delivery_option_carrier tr td {
	padding: 5px;
}

.delivery_option_carrier.selected {
	display: table;
}

.delivery_option_carrier.not-displayable {
	display: none;
}

/* --------------------------------------------------------------------------------------------------------
									 PAGES ADRESSES, IDENTITY 
----------------------------------------------------------------------------------------------------------- */

#address,
#identity,
#account-creation_form,
#new_account_form,
#opc_account_form,
#authentication {
	#center_column {
		.box {}
		p.required {
			color: #f13340;
			margin: 9px 0 16px 0;
		}
		p.inline-infos {
			color: red;
		}
		.form-group {
			margin-bottom: 4px;
			.form-control {
				max-width: 271px;
				&#adress_alias {
					margin-bottom: 20px;
				}
			}
			&.phone-number {
				@media (min-width: $screen-lg) { // min 1200px
					float: left;
					width: 270px;
					margin-right: 13px;
				}
				+ p {
					@media (min-width: $screen-lg) {
						padding: 23px 0 0 0;
						margin-bottom: 0;
					}
				}
			}
		}
		.gender-line {
			margin-bottom: 4px;
			padding-top: 4px;
			> label {
				margin-right: 10px;
				margin-bottom: 0;
			}
			.radio-inline {
				label {
					font-weight: normal;
					color: #777777;
				}
			}
		}
	}
}

#identity,
#authentication,
#order-opc {
	#center_column {
		form.std {
			.row {
				margin-left: -5px;
				margin-right: -5px;
				.col-xs-4 {
					padding-left: 5px;
					padding-right: 5px;
					max-width: 94px;
					.form-control {
						max-width: 84px;
					}
				}
			}
		}
		.footer_links {
			border: none;
			padding-top: 0;
		}
	}
}

#new_account_form .box {
	line-height: 20px;
	.date-select {
		padding-bottom: 10px;
	}
	.top-indent {
		padding-top: 10px;
	}
	.customerprivacy {
		padding-top: 15px;
	}
}

#authentication {
	fieldset.account_creation {}
}

/* -----------------------------------------------------------------------------------------------------
											 PASSWORD 
-------------------------------------------------------------------------------------------------------- */

#password {
	.box {
		p {
			margin-bottom: 9px;
		}
		.form-group {
			margin-bottom: 20px;
			.form-control {
				width: 263px;
			}
		}
	}
	.footer_links {}
	padding-top: 0;
	border: none;
}

/* ------------------------------------------------------------------------------------------------------
											 PAGE SEARCH 
--------------------------------------------------------------------------------------------------------- */

#instant_search_results {
	a.close {
		font-size: 13px;
		font-weight: 500;
		opacity: 1;
		line-height: inherit;
		text-transform: none;
		font-family: $font-family;
		color: $link-color;
		&:hover {
			color: $link-hover-color;
		}
		@media (max-width: $screen-xs) {
			float: none;
			display: block;
			padding-top: 5px;
		}
	}
}

/* ---------------------------------------------------------------------------------------------------------
										 MANUFACTURERS/SUPPLIERS 
------------------------------------------------------------------------------------------------------------ */

#manufacturer, #supplier {
	.top-pagination-content,
	.bottom-pagination-content {
		div.pagination .showall {
			margin-right:0;	
		}
	}
}
#manufacturers_list,
#suppliers_list {
	h3 {
		font-size: 17px;
		line-height: 23px;
	}
	.description {
		line-height: 18px;
	}
	.product-counter {
		a {
			font-weight: bold;
			&:hover {}
		}
	}
	&.list {
		li {
			.mansup-container {
				border-top: 1px solid $base-border-color;
				padding: 31px 0 30px 0;
				@media (max-width: $screen-xs-max) { // < 768
					text-align: center;
				}
			}
			.logo {
				@media only screen and (max-width:$screen-xs-max) {
					margin-bottom:10px;	
				}	
			}
			&:first-child {
				.mansup-container {
					border-top: 0;
				}
			}
			.left-side {
				text-align: center;
			}
			h3 {
				margin:0 0 0 0;
				padding-bottom: 8px;
			}
			.lnk_img, .logo {
				img {
					display:inline-block;	
				}
			}
			.middle-side {
				padding-left: 0;
				@media (max-width: $screen-xs-max) { // < 768
					padding-right: 15px;
					padding-left: 15px;
				}
			}
			.right-side-content {
				border-left: 1px solid $base-border-color;
				padding: 0 0 32px 31px;
				min-height: 108px;
				@media (max-width: $screen-xs-max) { // < 768
					min-height: 1px;
					padding: 0;
					border: none;
				}
			}
		}
	}
	&.grid {
		li {
			text-align: center;
			.product-container {
				padding-top: 40px;
				padding-bottom: 20px;
				border-bottom: 1px solid $base-border-color;
				.left-side {
					padding-bottom: 42px;
				}
				h3 {
					padding-bottom: 10px;
				}
				.product-counter {
					margin-bottom: 12px;
				}
			}
		}
		@media (min-width: $screen-lg) { // > 1199
			li.first-in-line {
				clear: left;
			}
			li.last-line {
				.product-container {
					border-bottom: none;
				}
			}
		}
		@media (min-width: $screen-sm) and (max-width: $screen-md-max) { //768 -> 1199
			li.first-item-of-tablet-line {
				clear: left;
			}
			li.last-tablet-line {
				.product-container {
					border-bottom: none;
				}
			}
		}
		@media (max-width: $screen-xs-max) { // < 768
			li.item-last {}
			.product-container {
				border-bottom: none;
			}
		}
	}
}

#manufacturer,
#supplier {
	.description_box {
		padding-top: 20px;
		padding-bottom: 20px;
		border-top: 1px solid $base-border-color;
		.hide_desc {
			display: none;
		}
	}
	.bottom-pagination-content,
	.top-pagination-content {
		min-height: 69px;
	}
	.top-pagination-content.bottom-line {
		border-bottom: 1px solid $base-border-color;
	}
}

/* ---------------------------------------------------------------------------------------------------------------------
											 DISCOUNT PAGE 
------------------------------------------------------------------------------------------------------------------------ */

table.discount {
	i {
		font-size: 20px;
		line-height: 20px;
		vertical-align: -2px;
		&.icon-ok {
			color: #46a74e;
		}
		&.icon-remove {
			color: #f13340;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------
											 GUEST TRACKING 
--------------------------------------------------------------------------------------------------------------------------- */

#guestTracking {
	.form-control {
		max-width: 271px;
	}
	@media (min-width: $screen-lg) { // > 1200px
		.form-group {
			overflow: hidden;
			i {
				padding-left: 10px;
			}
		}
		.form-control {
			float: left;
		}
		label {
			float: left;
			clear: both;
			width: 100%;
		}
	}
}

/* ------------------------------------------------------------------------------------------------------------------------------
													 PAGE 404 
--------------------------------------------------------------------------------------------------------------------------------- */

#pagenotfound {
	.pagenotfound {
		max-width: 824px;
		margin: 0 auto;
		text-align: center;
		.img-404 {
			padding: 8px 0 27px 0;
			img {
				max-width: 100%;
			}
		}
		h1 {
			font: 600 28px / 34px $font-custom;
			color: $base-text-color;
			text-transform: uppercase;
			margin-bottom: 7px;
		}
		p {
			font: 600 16px / 20px $font-custom;
			color: #555454;
			text-transform: uppercase;
			border-bottom: 1px solid $base-border-color;
			padding-bottom: 19px;
			margin-bottom: 20px;
		}
		h3 {
			font-weight: bold;
			color: $base-text-color;
			font-size: 13px;
			line-height: normal;
			margin-bottom: 18px;
		}
		label {
			font-weight: normal;
			@media (max-width: $screen-xs-max) {
				display: block;
			}
		}
		.form-control {
			max-width: 293px;
			display: inline-block;
			margin-right: 5px;
			// max 767px
			@media (max-width: $screen-xs-max) {
				margin: 0 auto 15px auto;
				display: block;
			}
		}
		.buttons {
			padding: 48px 0 20px 0;
			.btn-md {
				i.left {
					font-size: 17px;
				}
			}
		}
	}
}

/* -------*--------------------------------------------------------------------------------------------------------
										addon RerversoForm
------------------------------------------------------------------------------------------------------------------- */

#account-creation_form fieldset.reversoform {
	padding: 10px !important;
}

#account-creation_form fieldset.reversoform .text label {
	display: block;
	padding: 0 0 10px 0;
	width: 100%;
	text-align: left;
}

#account-creation_form fieldset.reversoform .infos-sup {
	padding: 0 !important;
	font-size: 10px;
	font-style: italic;
	text-align: right;
}

/* -------*--------------------------------------------------------------------------------------------------------
									 addon customer privacy
-*----------------------------------------------------------------------------------------------------------------- */
#account-creation_form fieldset.customerprivacy label,
fieldset.customerprivacy label {
	cursor: pointer;
}

/* -------*--------------------------------------------------------------------------------------------------------
									 addon referralprogram
------------------------------------------------------------------------------------------------------------------- */
.tab-content {
	margin-top: 20px;
	> ul {
		margin-bottom:0;	
	}
}

/* -----------------------------------------------------------------------------------------------------------------
									 addons comments products
-------------------------------------------------------------------------------------------------------------------- */
form#sendComment fieldset {
	padding: 10px;
	border: 1px solid #cccccc;
	background: #eeeeee;
}

form#sendComment h3 {
	font-size: 14px;
}

#new_comment_form p.text {
	margin-bottom: 0;
	padding-bottom: 0;
}

#sendComment p.text label,
#sendComment p.textarea label {
	display: block;
	margin: 12px 0 4px;
	font-size: 12px;
}

#sendComment p.text input {
	padding: 0 5px;
	height: 28px;
	width: 498px;
	border: 1px solid #cccccc;
	background: url(../img/bg_input.png) repeat-x 0 0 $light-background;
}

#sendComment p.textarea textarea {
	height: 80px;
	width: 508px;
	border: 1px solid #cccccc;
}

#sendComment p.submit {
	padding: 0;
	text-align: right;
}

#sendComment p.closeform {
	float: right;
	padding: 0;
	height: 12px;
	width: 12px;
	text-indent: -5000px;
	background: url(../img/icon/delete.gif) no-repeat 0 0;
}

.star {
	position: relative;
	top: -1px;
	float: left;
	width: 14px;
	overflow: hidden;
	cursor: pointer;
	font-size: 14px;
	font-weight: normal;
}

.star {
	display: block;
	a {
		display: block;
		position: absolute;
		text-indent: -5000px;
	}
}

div.star {
	&:after {
		content: "\f006";
		font-family: $font-icon;
		display: inline-block;
		color: #bbc2c7;
	}
}

div.star.star_on {
	display: block;
	&:after {
		content: "\f006";
		font-family: $font-icon;
		display: inline-block;
		color: #333;
	}
}

div.star.star_hover {
	&:after {
		content: "\f006";
		font-family: $font-icon;
		display: inline-block;
		color: #000;
	}
}

/* ------- CSS Modules ------- */
/* ------- IMPORTANT - Internet Explorer can read 31 CSS files max. Some CSS have been put here and erased from their own module folder. ------- */

/* -------*--------------------------------------------------------------------------------------------------------
															 Block ADVERTISING 
------------------------------------------------------------------------------------------------------------------- */
.advertising_block {
	width: 191px;
	margin-bottom: 1em;
	text-align: center;
}

/* -------*-------------------------------------------------------------------------------------------------------- 
															footer 
------------------------------------------------------------------------------------------------------------------- */
.blockcategories_footer ul ul {
	display: none !important;
}

/* -------*--------------------------------------------------------------------------------------------------------
														 Form validate 
------------------------------------------------------------------------------------------------------------------- */

.form-group {
	&.form-error {
		input,
		textarea {
			border: 1px solid #f13340;
			color: #f13340;
			background: url(../img/icon/form-error.png) 98% 5px no-repeat #fff1f2;
		}
	}
	&.form-ok {
		input,
		textarea {
			border: 1px solid #46a74e;
			color: #35b33f;
			background: url(../img/icon/form-ok.png) 98% 5px no-repeat #ddf9e1;
		}
	}
	&.form-error,
	&.form-ok {
		.form-control {
			padding-right: 30px;
		}
	}
}

/* -------*--------------------------------------------------------------------------------------------------------
											 uniform 
------------------------------------------------------------------------------------------------------------------- */

.radio-inline,
.checkbox {
	padding-left: 0;
	.checker {
		float: left;
		span {
			top: 0;
		}
	}
	div.radio {
		display: inline-block;
		span {
			float: left;
			top: 0;
		}
	}
}
.checkbox .checker input[type="checkbox"],
.checkbox-inline .checker input[type="checkbox"] {
	float:none;	
}

.fancybox-skin {
	background: $light-background !important;
}

.fancybox-skin .fancybox-close {
	width: 28px;
	height: 28px;
	background: none;
	font-size: 28px;
	line-height: 28px;
	color: $base-text-color;
	text-align: center;
	background: white;
	@include border-radius(50px);
	&:hover {
		color: $link-color;
	}
	&:after {
		content: "\f057";
		font-family: $font-icon;
	}
}

/* -------*--------------------------------------------------------------------------------------------------------
												 Homepage pre-footer 
------------------------------------------------------------------------------------------------------------------- */

#facebook_block,
#cmsinfo_block {
	overflow: hidden;
	background: $homepage-footer-bg;
	min-height: 344px;
	padding-right: 29px;
	padding-left: 29px;
	@media (max-width: $screen-sm-max) { // max 991px
		min-height: 348px;
		padding-left: 13px;
		padding-right: 13px;
	}
	@media (max-width: $screen-xs-max) { // max 767px
		width: 100%;
		min-height: 1px;
	}
}

#facebook_block {
	h4 {
		padding: 35px 0 0 0;
		margin: 0 0 12px 0;
		font: 300 21px / 25px $font-custom;
		color: lighten($base-heading-color, 10%);
		@media (max-width: $screen-sm-max) { // max 991px
			font-size: 18px;
		}
		@media (max-width: $screen-xs-max) { // max 767px
			padding-top: 20px !important;
		}
		@media (max-width: $screen-sm) { // 768px
			font-size: 16px;
		}
	}
	.facebook-fanbox {
		background: $light-background;
		border: 1px solid #aaaaaa;
		padding-bottom: 10px;
	}
	
	@media only screen and (min-width:$screen-md) and (max-width:$screen-md-max) {
		min-height:357px;	
	}
}
.fb-like-box,
.fb-like-box span,
.fb-like-box span iframe[style] { 
	max-width: 100%;
}
.facebook-fanbox .fb-like-box iframe {
 @media (max-width: $screen-md-max) {
 overflow-y: scroll;
 }
}
#cmsinfo_block {
	border-left: 1px solid $homepage-footer-border;
	@media (max-width: $screen-xs-max) { // max 767px
		border: none;
		margin-top: 10px;
	}
	> div {
		padding: 35px 10px 0 0;
		@media (max-width: $screen-xs-max) { // max 767px
			padding-top: 20px;
		}
		@media (max-width: $screen-xs - 1) { // max 479px
			width: 100%;
			border-top: 1px solid $homepage-footer-border;
		}
	}
	> div + div {
		border-left: 1px solid $homepage-footer-border;
		min-height: 344px;
		padding-left: 29px;
		@media (max-width: $screen-xs - 1) { // max 479px
			border-left: none;
			padding-left: 10px;
			min-height: 1px;
			padding-bottom: 15px;
		}
		@media only screen and (min-width:$screen-md) and (max-width:$screen-md-max) {
			min-height:357px;	
		}
	}
	em {
		float: left;
		width: 60px;
		height: 60px;
		margin: 3px 10px 0 0;
		font-size: 30px;
		color: $light-text-color;
		line-height: 60px;
		text-align: center;
		background: lighten($base-heading-color, 10%);
		@include border-radius(100px);
		@media (max-width: $screen-sm-max) { // max 991px
			width: 30px;
			height: 30px;
			line-height: 30px;
			font-size: 20px;
		}
	}
	.type-text {
		overflow: hidden;
	}
	h3 {
		margin: 0 0 5px 0;
		font: 300 21px / 25px $font-custom;
		color: lighten($base-heading-color, 10%);
		@media (max-width: $screen-md-max) { // max 1199px
			font-size: 18px;
		}
	}
	ul {
		li {
			padding-bottom: 22px;
			@media (max-width: $screen-md-max) { // max 1199px
				padding-bottom: 10px;
			}
			@media (max-width: $screen-sm-max) { // max 991px
				padding-bottom: 0;
			}
		}
	}
	p {
		em {
			background: none;
			@include border-radius(0);
			margin: 0;
			font-size: 13px;
			color: $text-color;
			float: none;
			height: inherit;
			line-height: inherit;
			text-align: left;
			font-style: italic;
		}
	}
}

.ie8 {
	#facebook_block,
	#cmsinfo_block {
		height: 344px;
	}
}

/* -------*--------------------------------------------------------------------------------------------------------
										 Zoom 
------------------------------------------------------------------------------------------------------------------- */

@media (max-width: $screen-md-max) { // max 1199px
	.zoomdiv {
		display: none !important;
	}
}

/* -------*--------------------------------------------------------------------------------------------------------
								 temporary fix for quickview 
------------------------------------------------------------------------------------------------------------------- */
#product.content_only {
	div.pb-left-column {
		p.resetimg {
			margin-bottom: 0;
			padding-top: 0;
		}
		#thumbs_list_frame {
			margin-bottom: 0;
		}
	}
}

/* -------*--------------------------------------------------------------------------------------------------------
									 Product Compare 
------------------------------------------------------------------------------------------------------------------- */
#products-comparison {
	.columns-container {
		padding-bottom:350px;	
	}
}
#product_comparison {
	td.compare_extra_information {
		padding: 10px;
	}
	#social-share-compare {
		margin-bottom: 50px;
		.socialsharing_product {
			button {
				text-align:center;
				margin-bottom:3px;
			}
		}
	}
}

/* -------*--------------------------------------------------------------------------------------------------------
										 Social Sharing 
------------------------------------------------------------------------------------------------------------------- */
.btn-facebook,
.btn-twitter,
.btn-google-plus,
.btn-pinterest {
	&:hover {
		border-color: white;
	}
}

.btn-facebook {
	i {
		color: #435f9f;
	}
	&:hover {
		color: white;
		background-color: #435f9f;
		i {
			color: white;
		}
	}
}

.btn-twitter {
	i {
		color: #00aaf0;
	}
	&:hover {
		color: white;
		background-color: #00aaf0;
		i {
			color: white;
		}
	}
}

.btn-google-plus {
	i {
		color: #e04b34;
	}
	&:hover {
		color: white;
		background-color: #e04b34;
		i {
			color: white;
		}
	}
}

.btn-pinterest {
	i {
		color: #ce1f21;
	}
	&:hover {
		color: white;
		background-color: #ce1f21;
		i {
			color: white;
		}
	}
}

#crossselling_list,
.accessories-block .block_content,
#productscategory_list {
	
		/* -------*-------------------------------------------------------------------------------------------------------- 
															RESET AND LAYOUT
		------------------------------------------------------------------------------------------------------------------- */
		
		.bx-wrapper {
			position: relative;
			padding: 0;
			margin:0 auto !important;
			*zoom: 1;
			@media only screen and (min-width:$screen-sm-max) {
				margin:0 !important	
			}
		}
		
		.bx-wrapper img {
			max-width: 100%;
			display: block;
		}
		
		/* -------*--------------------------------------------------------------------------------------------------------
																THEME
		------------------------------------------------------------------------------------------------------------------- */
		
		
		
		.bx-wrapper .bx-pager,
		.bx-wrapper .bx-controls-auto {
			position: absolute;
			bottom: -30px;
			width: 100%;
		}
		
		/* -------*--------------------------------------------------------------------------------------------------------
																 LOADER
		------------------------------------------------------------------------------------------------------------------- */
		
		.bx-wrapper .bx-loading {
			min-height: 50px;
			background: url(images/bx_loader.gif) center center no-repeat ;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2000;
			display:none
		}
		
		/* -------*--------------------------------------------------------------------------------------------------------
																 PAGER 
		------------------------------------------------------------------------------------------------------------------- */
		
		.bx-wrapper .bx-pager {
			text-align: center;
			font-size: .85em;
			font-family: $font-family;
			font-weight: bold;
			color: #666;
			padding-top: 20px;
		}
		
		.bx-wrapper .bx-pager .bx-pager-item,
		.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
			display: inline-block;
			*zoom: 1;
			*display: inline;
		}
		
		.bx-wrapper .bx-pager.bx-default-pager a {
			text-indent: -9999px;
			display: block;
			width: 10px;
			height: 10px;
			margin: 0 5px;
			background:#000;
			outline: 0;
			@include border-radius(5px);
		}
		
		.bx-wrapper .bx-pager.bx-default-pager a:hover,
		.bx-wrapper .bx-pager.bx-default-pager a.active {
			background: #000;
		}
		
		/* -------*--------------------------------------------------------------------------------------------------------
												 DIRECTION CONTROLS (NEXT / PREV) 
		------------------------------------------------------------------------------------------------------------------- */
		.bx-wrapper .bx-controls-direction a {
			margin-top:38px;
			height:20px;
			width:20px;
			line-height:0;
			position:absolute;
			top:40%;
			margin-top:-10px;
			font-size:0;
			overflow:hidden;
			&:before {
				padding-left:2px;
				color:#c0c0c0;
				font-family:$font-icon;
				font-size:20px;
				line-height:22px;	
			}
			&:hover:before {
				color:$base-text-color
			}
		}
		
		
		.bx-next {
			right: 10px;
		&:before {
			content: "\f138";	
			}
		}
		
		.bx-prev {
			left:10px;
		&:before {
			content: "\f137";
		
			}
		}		
		
		.bx-wrapper .bx-controls-direction a.disabled {
			display: none;
		}
		
		/* -------*--------------------------------------------------------------------------------------------------------
												 AUTO CONTROLS (START / STOP) 
		------------------------------------------------------------------------------------------------------------------- */
		
		.bx-wrapper .bx-controls-auto {
			text-align: center;
		}
		
		.bx-wrapper .bx-controls-auto .bx-start {
			display: block;
			text-indent: -9999px;
			width: 10px;
			height: 11px;
			outline: 0;
			background: url(images/controls.png) -86px -11px no-repeat;
			margin: 0 3px;
		}
		
		.bx-wrapper .bx-controls-auto .bx-start:hover,
		.bx-wrapper .bx-controls-auto .bx-start.active {
			background-position: -86px 0;
		}
		
		.bx-wrapper .bx-controls-auto .bx-stop {
			display: block;
			text-indent: -9999px;
			width: 9px;
			height: 11px;
			outline: 0;
			background: url(images/controls.png) -86px -44px no-repeat;
			margin: 0 3px;
		}
		
		.bx-wrapper .bx-controls-auto .bx-stop:hover,
		.bx-wrapper .bx-controls-auto .bx-stop.active {
			background-position: -86px -33px;
		}
		
		/* -------*--------------------------------------------------------------------------------------------------------
										 PAGER WITH AUTO-CONTROLS HYBRID LAYOUT 
		------------------------------------------------------------------------------------------------------------------- */
		
		.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
			text-align: left;
			width: 80%;
		}
		
		.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
			right: 0;
			width: 35px;
		}
		
		
		/* -------*--------------------------------------------------------------------------------------------------------
												 IMAGE CAPTIONS 
		------------------------------------------------------------------------------------------------------------------- */
		
		.bx-wrapper .bx-caption {
			position: absolute;
			bottom: 0;
			left: 0;
			background: #666\9;
			background: rgba(80, 80, 80, 0.75);
			width: 100%;
		}
		
		.bx-wrapper .bx-caption span {
			color: $light-text-color;
			font-family: $font-family;
			display: block;
			font-size: .85em;
			padding: 10px;
		}
}

.fancybox-overlay-fixed, #habla_window_div { -webkit-transform: translateZ(0);}
.fancybox-inner .fancybox-error {white-space:normal;}
.sortPagiBar.instant_search {display:none;}


.scroll-wrapper {
    overflow: hidden !important;
    padding: 0 !important;
    position: relative;
}
 
.scroll-wrapper > .scroll-content {
    border: none !important;
    box-sizing: content-box !important;
    height: auto;
    left: 0;
    margin: 0;
    max-height: none !important;
    max-width: none !important;
    overflow: scroll !important;
    padding: 0;
    position: relative !important;
    top: 0;
    width: auto !important;
}
 
.scroll-wrapper > .scroll-content::-webkit-scrollbar {
    height: 0;
    width: 0;
}
 
.scroll-element {
    display: none;
}
.scroll-element, .scroll-element div {
    box-sizing: content-box;
}
 
.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
    display: block;
}
 
.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
    cursor: default;
}
 
.scroll-textarea {
    border: 1px solid #cccccc;
    border-top-color: #999999;
}
.scroll-textarea > .scroll-content {
    overflow: hidden !important;
}
.scroll-textarea > .scroll-content > textarea {
    border: none !important;
    box-sizing: border-box;
    height: 100% !important;
    margin: 0;
    max-height: none !important;
    max-width: none !important;
    overflow: scroll !important;
    outline: none;
    padding: 2px;
    position: relative !important;
    top: 0;
    width: 100% !important;
}
.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
    height: 0;
    width: 0;
}
 
 
 
 
/*************** SIMPLE INNER SCROLLBAR ***************/
 
.scrollbar-inner > .scroll-element,
.scrollbar-inner > .scroll-element div
{
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
}
 
.scrollbar-inner > .scroll-element div {
    display: block;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
	box-sizing:border-box;
}
 
.scrollbar-inner > .scroll-element.scroll-x {
    bottom: 2px;
    height: 8px;
    left: 0;
    width: 100%;
}
 
.scrollbar-inner > .scroll-element.scroll-y {
    height: 60%;
    right: 10px;
    top: 20%;
    width: 8px;
	opacity:0.2;
	&:hover {
		opacity:1;	
	}
}
 
.scrollbar-inner > .scroll-element .scroll-element_outer {
    overflow: hidden;
	 background:#fff; border:1px solid #000;
}
 
.scrollbar-inner > .scroll-element .scroll-element_outer,
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
}
 
.scrollbar-inner > .scroll-element .scroll-element_track {}
.scrollbar-inner > .scroll-element .scroll-bar { background-color: #333; border:1px solid #fff; border-top-width:2px; border-bottom-width:2px; box-sizing:border-box; }
.scrollbar-inner > .scroll-element:hover .scroll-bar { background-color: #000; }
.scrollbar-inner > .scroll-element.scroll-draggable .scroll-bar { background-color: #919191; }
 
 
/* update scrollbar offset if both scrolls are visible */
 
.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track { left: -12px; }
.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track { top: -12px; }
 
 
.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size { left: -12px; }
.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size { top: -12px; }